<template>
  <!-- Attachment -->
  <b-card v-if="order.attachments !== null && order.attachments.length > 0">
    <b-form-group
      label-cols-lg="3"
      label="Attachments"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group class="mt-4">
        <b-list-group
          v-for="attachment in order.attachments"
          :key="attachment.id"
        >
          <b-list-group-item
            v-if="attachment.url"
            :href="attachment.url"
            target="_blank"
          >
            <i class="fas fa-file-image"></i>
            {{ attachment.url.substring(attachment.url.lastIndexOf("/") + 1) }}
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
    </b-form-group>
  </b-card>
  <!-- END Attachments -->
</template>

<script>
export default {
  name: "AttachmentList",
  props: ["order"]
};
</script>

<style></style>
