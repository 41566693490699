<template>
  <b-button variant="primary" @click="sendOrder" v-if="order.statusNo === '1'"
    ><i class="fas fa-envelope-open-text"></i> Send
  </b-button>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import config from "@/store/config.js";

export default {
  name: "SendOrderButton",
  props: ["order"],
  methods: {
    ...mapActions(["loadOrder"]),
    sendOrder() {
      let self = this;
      axios
        .get(`${config.API}/status/10`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;
          self.$store
            .dispatch("updateCreateCurrentOrder", self.order)
            .then(() => {
              self.error = null;
              self.loadOrder(self.order.id);
            })
            .catch(e => {
              self.error = e;
            });
        })
        .catch(e => {
          self.error = e;
        });
    }
  }
};
</script>

<style></style>
