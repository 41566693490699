<template>
  <b-button class="mr-2" @click="shareOrder">
    <i class="fas fa-share-alt"></i> Share
  </b-button>
</template>

<script>
export default {
  name: "ShareOrderButton",
  props: ["orderId"],
  methods: {
    shareOrder() {
      this.$router.push(`/order/shared/${this.orderId}`);
    }
  }
};
</script>

<style></style>
