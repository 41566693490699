<template>
  <div v-cloak>
    <!-- spinner -->
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-container fluid v-else>
      <b-row>
        <b-col>
          <!-- Info about the order -->
          <b-card>
            <b-container fluid>
              <Status-Badge
                class="float-right"
                :status="order.statusName"
              ></Status-Badge>
              <h4><i class="fas fa-file-alt"></i> Order {{ order.orderNo }}</h4>
              <hr />
              <b-list-group>
                <b-list-group-item>
                  <i class="fas fa-building"></i> Project:
                  <strong>{{ order.projectName }}</strong>
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-calendar"></i> Created:
                  <strong>{{ order.created | formatLongDateTime }}</strong>
                </b-list-group-item>
                <b-list-group-item
                  ><i class="fas fa-address-book mr-2"></i>
                  {{ order.orderedBy }}
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-phone mr-2"></i>
                  <a :href="`tel:${order.phone}`">{{ order.phone }}</a>
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-envelope-open-text mr-2"></i>
                  <a :href="`mailto:${order.mail}`">{{ order.mail }}</a>
                </b-list-group-item>
                <b-list-group-item v-if="order.notes">
                  <p><i class="fas fa-sticky-note"></i> Notes:</p>
                  <b-form-textarea
                    v-model="order.notes"
                    rows="3"
                    max-row="6"
                    readonly
                  ></b-form-textarea>
                </b-list-group-item>
              </b-list-group>
            </b-container>
          </b-card>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-card>
                <b-container fluid>
                  <b-row>
                    <b-col>
                      <h4>
                        <i class="mr-1 fas fa-calendar-alt"></i> Delivery
                        details
                      </h4>
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-list-group>
                        <b-list-group-item>
                          <i class="fas fa-calendar mr-2"></i> Preferred
                          delivery:
                          <strong>{{ preferredDeliveryDate }} </strong>
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-map-marker-alt mr-2"></i>
                          {{ order.deliveryAddress.streetName }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-map mr-2"></i>
                          {{ order.deliveryAddress.postalCode }},
                          {{ order.deliveryAddress.city }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-address-book mr-2"></i>
                          {{ order.contanctPersonOnSite }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-phone mr-2"></i>
                          <a :href="`tel:${order.phone}`">{{
                            order.contactPersonOnSitePhone
                          }}</a>
                        </b-list-group-item>
                        <b-list-group-item
                          v-if="order.contactPersonOnSiteComments"
                        >
                          Comments:
                          <p>{{ order.contactPersonOnSiteComments }}</p>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Action buttons START -->
      <b-row class="d-print-none">
        <b-col>
          <b-card>
            <b-button
              variant="danger"
              @click="closedorder"
              class="mr-2"
              v-if="
                this.getCurrentOrder.statusNo === '40' &&
                  this.getUser.role === 'Admin'
              "
              ><i class="fas fa-briefcase"></i> Close</b-button
            >
            <b-button
              variant="primary"
              @click="deliviredOrder"
              class="mr-2"
              v-if="
                this.getCurrentOrder.statusNo === '30' &&
                  this.getUser.role === 'Admin'
              "
              ><i class="fas fa-briefcase"></i> Delivired</b-button
            >
            <b-button
              variant="primary"
              @click="sendtOrder"
              class="mr-2"
              v-if="this.statusNo === '20' && this.getUser.role === 'Admin'"
              ><i class="fas fa-briefcase"></i> Processed</b-button
            >
            <Start-Order-Button
              :order="this.order"
              v-if="this.statusNo === '10' && this.getUser.role === 'Admin'"
            ></Start-Order-Button>
            <Send-Order-Button
              :order="this.order"
              key="sendOrderBtn"
              class="mr-2"
            ></Send-Order-Button>
            <b-button
              class="mr-2"
              variant="outline-secondary"
              v-if="order.attachments !== null && order.attachments.length > 0"
              @click="toggleAttachments"
            >
              <span v-if="this.showAttachments"
                ><i class="fas fa-toggle-on"></i> Attachment(s)</span
              >
              <span v-else
                ><i class="fas fa-toggle-off"></i> Attachment(s)</span
              >
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-secondary"
              @click="toggleTable"
            >
              <span v-if="this.showTable"
                ><i class="fas fa-toggle-on"></i> Table</span
              >
              <span v-else><i class="fas fa-toggle-off"></i> Table</span>
            </b-button>
            <Share-Order-Button :orderId="orderId"></Share-Order-Button>
            <b-button
              variant="info"
              @click="goToEdit"
              v-if="this.order.statusNo <= '10' || this.user.role === 'Admin'"
              class="mr-2"
              ><i class="fas fa-pen-square"></i>
              {{ $t("items.edit") }}</b-button
            >
            <Back-Button class="mr-2"></Back-Button>
          </b-card>
        </b-col>
      </b-row>
      <!-- Action buttons END -->

      <!-- Attachment list START -->
      <b-row class="d-print-none" v-if="showAttachments">
        <b-col>
          <Attachment-List :order="order"></Attachment-List>
        </b-col>
      </b-row>
      <!-- Attachment list END -->

      <!-- Table START -->
      <b-row>
        <b-col>
          <b-alert
            v-cloak
            v-if="this.order.orderLines.length === 0"
            show
            variant="danger"
            >No order lines.</b-alert
          >
          <Order-Lines-Card :order="order" v-if="!showTable"></Order-Lines-Card>
          <Order-Lines-Table
            v-else
            :order="order"
            class="mt-2"
          ></Order-Lines-Table>
        </b-col>
      </b-row>
      <!-- Table END -->
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrderLinesTable from "@/components/OrderLinesTable";
import OrderLinesCard from "@/components/OrderLinesCard";
import SendOrderButton from "@/components/Buttons/SendOrderButton";
import StartOrderButton from "@/components/Buttons/StartOrderButton";
import BackButton from "@/components/Buttons/BackButton";
import StatusBadge from "@/components/StatusBadge";
import axios from "axios";
import config from "@/store/config.js";
import moment from "moment";
import AttachmentList from "@/components/AttachmentList";
import ShareOrderButton from "@/components/Buttons/ShareOrderButton";

export default {
  name: "orderView",
  components: {
    OrderLinesTable,
    OrderLinesCard,
    SendOrderButton,
    StartOrderButton,
    BackButton,
    StatusBadge,
    AttachmentList,
    ShareOrderButton
  },
  data() {
    return {
      orderId: null,
      user: {},
      isLoading: true,
      error: null,
      contactDetails: null,
      projectId: "",
      order: {
        orderNo: -99,
        deliveryAddress: {
          streetName: ""
        },
        orderLines: []
      },
      showTable: true,
      showAttachments: false
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.user = this.getUser;
    var self = this;
    this.orderId = this.$route.params.id || null;
    if (this.orderId) {
      this.loadOrder(this.orderId)
        .then(r => {
          self.order = r.data;
          self.isLoading = false;
        })
        .catch(error => {
          this.error = error;
        });
    }
  },
  methods: {
    closedorder() {
      let self = this;

      axios
        .get(`${config.API}/status/50`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;

          self.$store
            .dispatch("updateCreateCurrentOrder", self.order)
            .then(function() {
              self
                .loadOrder(self.order.id)
                .then(r => {
                  self.order = r.data;
                  self.isLoading = false;
                })
                .catch(error => {
                  this.error = error;
                });
            })
            .catch(e => {
              self.error = e;
            });
        })
        .catch(e => {
          self.error = e;
        });
    },
    deliviredOrder() {
      let self = this;

      axios
        .get(`${config.API}/status/40`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;

          self.$store
            .dispatch("updateCreateCurrentOrder", self.order)
            .then(function() {
              self
                .loadOrder(self.order.id)
                .then(r => {
                  self.order = r.data;
                  self.isLoading = false;
                })
                .catch(error => {
                  this.error = error;
                });
            })
            .catch(e => {
              self.error = e;
            });
        })
        .catch(e => {
          self.error = e;
        });
    },
    sendtOrder() {
      let self = this;
      this.isLoading = true;

      axios
        .get(`${config.API}/status/30`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;

          self.$store
            .dispatch("updateCreateCurrentOrder", self.order)
            .then(function() {
              // self.$router.push(`/order/view/${self.order.id}`);
              self.error = null;
              self
                .loadOrder(self.order.id)
                .then(r => {
                  self.order = r.data;
                  self.isLoading = false;
                })
                .catch(error => {
                  this.error = error;
                });
            })
            .catch(e => {
              self.error = e;
              this.isLoading = false;
            });
        })
        .catch(e => {
          self.error = e;
        });
    },
    goToEdit() {
      this.$router.push(`/order/edit/${this.order.id}`);
    },
    toggleTable() {
      this.showTable = !this.showTable;
    },
    toggleAttachments() {
      this.showAttachments = !this.showAttachments;
    },
    ...mapActions(["setProject", "loadOrder", "getProjects"])
  },
  computed: {
    ...mapGetters([
      "getCurrentProject",
      "getOrderStatus",
      "allProjects",
      "getCurrentOrder",
      "getUser"
    ]),
    statusNo: {
      get() {
        return this.getCurrentOrder.statusNo;
      }
    },
    description: {
      get() {
        return this.$store.state.orders.order.description || "";
      },
      set(value) {
        this.$store.commit("setOrderDescription", value);
      }
    },
    preferredDeliveryDate: {
      get() {
        return moment(String(this.order.preferredDeliveryDate)).format(
          "DD/MM/YYYY"
        );
      },
      set(value) {
        this.order.preferredDeliveryDate = value;
      }
    }
  }
};
</script>

<style>
[v-cloak] {
  display: none;
}

.black {
  color: black;
}
</style>
