<template>
  <b-button variant="primary" @click="startOrder" class="mr-2"
    ><i class="fas fa-briefcase"></i> Start</b-button
  >
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import config from "@/store/config.js";

export default {
  name: "StartOrderButton",
  props: ["order"],
  methods: {
    ...mapActions(["loadOrder"]),
    startOrder() {
      let self = this;

      axios
        .get(`${config.API}/status/20`)
        .then(r => {
          self.order.statusNo = r.data.statusNo;
          self.order.statusName = r.data.name;

          self.$store
            .dispatch("updateCreateCurrentOrder", self.order)
            .then(function() {
              self.loadOrder(self.order.id);
            })
            .catch(e => {
              self.error = e;
            });
        })
        .catch(e => {
          self.error = e;
        });
    }
  },
  computed: {
    ...mapGetters(["getUser", "getCurrentOrder"]),
    statusNo: {
      get() {
        return this.getCurrentOrder.statusNo;
      },
      set(value) {
        this.order.statusNo = value;
      }
    }
  }
};
</script>

<style></style>
